import React, { useEffect, useState } from "react";
import AdminSideBar from "../AdminSideBar";
import { useParams } from "react-router-dom";
import CourseCard from "./CourseCard";
import Topics from "./Topics";
import axiosInstance from "../../config/axiosInstance"
import TopicForm from "../partials/TopicForm";


function ViewCourse() {
    const { id } = useParams()

    useEffect(() => {
        fetchCourseData()
        setSelectedPlans([])
    }, [])

    const [isLoading, setIsLoading] = useState(false)
    const [topic, setTopic] = useState({ "id": "", "description": "", lessons: [] })
    const [course, setCourse] = useState(null)
    const [topics, setTopics] = useState([])
    const [topicFormData, setTopicFormData] = useState({
        "company_service": id,
        "plan_value_proposition": [],
    })
    const [alertType, setAlertType] = useState("info")
    const [topicResponseMessage, setTopicResponseMessage] = useState("")
    const [isSendingTopicData, setIsSendingTopicData] = useState(false)
    const [isCreatingNewTopic, setIsCreatingNewTopic] = useState(false)
    const fetchCourseData = async () => {
        setIsLoading(true)
        try {
            const response = await axiosInstance.get(`/services/${id}/`)
            const { service, status, plan_value_proposition } = response.data
            if (status === "success") {
                setCourse(service)
                setTopics(service.topics)
                const pvp = plan_value_proposition.map(item => ({ ...item, isChecked: false }))
                setPlanValuePropositions(pvp)
            }
        } catch (err) {
            console.log(err.message);
        } finally {
        }

        setIsLoading(false)
    }

    const handleTopicFormDataChange = (event) => {
        const { name, value } = event.target;
        setTopicFormData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const deleteTopicFromCourse = async (topic) => {
        if (!window.confirm(`Remove : ${topic.plan_value_proposition.description} : from this course`)) return
        setTopics(prev => ([...prev.filter(item => item.id != topic.id)]))
        try {
            await axiosInstance.delete(`/course-topics/${topic.id}`)
            window.alert(` ${topic.plan_value_proposition.description} ; was successfully deleted`)
        } catch (error) {
            window.alert(` Error ocurred , try again later!`)
        }

    }


    const [planValuePropositions, setPlanValuePropositions] = useState([])
    const [selectedPlans, setSelectedPlans] = useState([])
    const handleTopicForm = async (e) => {
        e.preventDefault()
        setIsSendingTopicData(true)
        setTopicResponseMessage("Submitting information , please wait.....")

        try {
            const response = await axiosInstance.post(`/course-topics/`, topicFormData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            const responseData = response.data
            setTopics(responseData.data)
            setAlertType("success")
            setTopicResponseMessage("Topic created successfully!")
            setPlanValuePropositions(prev => prev.map(item => ({ ...item, isChecked: false })))
            setTimeout(() => {
                setIsSendingTopicData(false)
                setTopicResponseMessage("")
                setAlertType("info")
                setTopicFormData({
                    "company_service": id,
                    "plan_value_proposition": [],
                })
                setSelectedPlans([])
                setIsCreatingNewTopic(prev => !prev)
            }, 2000)
        } catch ({ message }) {
            setTopicResponseMessage(`Error : ${message}`)
            // console.log(error)
        } finally {
            setTimeout(() => {
                setIsSendingTopicData(false)
            }, 2000)
        }
    }

    useEffect(() => {
    }, [selectedPlans])

    const removeDuplicatesFromSelectedPlan = (arr) => {
        // Remove duplicates based on the "id" key
        const uniqueArray = arr.filter((item, index, self) =>
            index === self.findIndex((t) => t.id === item.id)
        );
        setSelectedPlans(uniqueArray)
    }

    const handlePlanCheckBox = (plan) => {

        removeDuplicatesFromSelectedPlan(selectedPlans)
        const findSelectedPlan = selectedPlans.find(p => p.id == plan.id)
        if (findSelectedPlan) {
            setSelectedPlans(previousArray => ([...previousArray.filter(p => p.id !== plan.id)]))

        } else {
            setSelectedPlans(previousArray => ([...previousArray, plan]))
        }

        setPlanValuePropositions(prev => prev.map(item => {
            if (item.id == plan.id) {
                const prev_plan_value_proposition = topicFormData.plan_value_proposition
                const prev = prev_plan_value_proposition.find(prev_id => prev_id == plan.id)

                if (!prev) {
                    const new_plan_value_proposition = [...prev_plan_value_proposition, plan.id]
                    setTopicFormData(prev => ({ ...prev, plan_value_proposition: new_plan_value_proposition }))
                } else {
                    const new_plan_value_proposition = topicFormData.plan_value_proposition.filter(item => item !== plan.id)
                    setTopicFormData(prev => ({ ...prev, plan_value_proposition: new_plan_value_proposition }))
                }

                if (item.isChecked) {
                    return { ...item, isChecked: false }
                } else {
                    return { ...item, isChecked: true }
                }

            }
            return item

        }))

    }

    return (
        <div className="container-fluid">
            <div className="row">

                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10 row">
                    <div className="col-md-3">
                        <div className={`loader  ${isLoading ? '' : 'd-none'}`}></div>
                        <h4 className="text-left">Course</h4>
                        {course ? <CourseCard course={course} /> :
                            <div className="alert alert-info">Course information not found</div>}
                    </div>
                    <div className="col-md-9">
                        <h4 className="text-left">Topics</h4>

                        <button className={`btn btn-outline-${!isCreatingNewTopic ? "primary" : "secondary"} btn-sm`} type="button"
                            onClick={() => setIsCreatingNewTopic(prev => !prev)}
                            style={{ position: "absolute", right: "40px", top: "63px" }}> {!isCreatingNewTopic ? "Add new Topic" : "Cancel operation"}</button>
                        <div className={`row ${!isCreatingNewTopic ? "d-none" : ""}`}>
                            <TopicForm
                                planValuePropositions={planValuePropositions}
                                handleTopicForm={handleTopicForm}
                                handlePlanCheckBox={handlePlanCheckBox}
                                topicFormData={topicFormData}
                                handleTopicFormDataChange={handleTopicFormDataChange}
                                topicResponseMessage={topicResponseMessage}
                                alertType={alertType}
                                isSendingTopicData={isSendingTopicData}
                                selectedPlans={selectedPlans}
                                isServiceDefined={true}
                            />
                        </div>

                        <div className={`row ${isCreatingNewTopic ? "d-none" : ""}`}>
                            {
                                topics.length > 0 ? <Topics topics={topics} deleteTopicFromCourse={deleteTopicFromCourse} /> : <div className="alert alert-info">This course has no topics.</div>
                            }
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewCourse;