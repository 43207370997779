import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';

function Navbar() {
    const navigate = useNavigate();
    const location = useLocation();
    const { isLoggedIn, logout,login } = useAuth();

    useEffect(() => {
        const authToken = localStorage.getItem("authToken");
        if (authToken) {
            login();
        }
    }, [login]); 

    const handleLogOut = () => {
        localStorage.clear("authToken");
        logout();
        navigate('/login');
    };

    if (!isLoggedIn || location.pathname == "/login") return null;
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light mb-1">
            <Link className="navbar-brand " to="/admin">Zenithup</Link>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarContent"
                aria-controls="navbarContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <Link className="nav-link " to="/admin">
                            Home <span className="sr-only">(current)</span>
                        </Link>
                    </li>
                </ul>
                <div className="d-flex ms-auto">
                    <button className="btn btn-sm btn-outline-info" onClick={() => handleLogOut()}>
                        Logout
                    </button>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
