import React, { useEffect, useState } from "react";
import AdminSideBar from "../AdminSideBar";
import { Link, useParams } from "react-router-dom";
import CourseCard from "../Courses/CourseCard";
import LessonForm from "./LessonForm";
import Lesson from "./Lesson";
import CreateTopicLessonFile from "./CreateTopicLessonFile";
import axiosInstance from "../../config/axiosInstance";


function CreateTopicLesson() {
    const { courseId, topicId } = useParams()
    const [course, setCourse] = useState(null)
    const [topics, setTopics] = useState([])
    const [lessons, setLessons] = useState([])
    const [topic, setTopic] = useState(null)
    const [isFormSendingData, setIsFormSendingData] = useState(false)
    const [responseMessage, setResponseMessage] = useState("")


    useEffect(() => {
        fetchCourseData()
    }, [])

    const [lessonFormData, setLessonFormData] = useState({
        "description": "",
        "name": ""
    })


    const fetchCourseData = async () => {

        try {
            const responseData = await axiosInstance.get(`/services/${courseId}`)
            const {service, } = responseData.data
            setCourse(service)
            setTopics(service.topics)
            console.log(service.topics)
            const currentTopic = service.topics.find(item=>item.id==topicId)
            setTopic(currentTopic)
            setLessons(currentTopic.lessons)
        } catch (err) {
            console.log(err)
        } finally {
        }


    }

    const handleInputOnChange = (event) => {
        const { name, value } = event.target;
        setLessonFormData({
            ...lessonFormData,
            [name]: value,
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        if(isEdditingLesson){
            updateLesson()
            return
        }
        const formData = { ...lessonFormData, "course_topic": topicId }
        setResponseMessage("Sending data, please wait....")
        setIsFormSendingData(true)

        try {
            const response = await axiosInstance.post(`/topic-lessons/`, formData)
            const responseData = response.data
            const status = response.status
            const { message, data } = responseData
            setResponseMessage(message)
            if(status==201){
                setLessonFormData({
                    "description": "",
                    "name": ""
                })
                setLessons((previousItems) => [data, ...previousItems])
            }
        } catch ({message}) {
            console.log(message)
            setResponseMessage(`Error ocurred! ${message}`)
        } finally {
            setTimeout(()=>{
                setResponseMessage("")
                setIsFormSendingData(false)
            },1000)
        }
    }

    const [userAlertMessage, setUserAlertMessage] = useState(null)
    const deleteTopicLesson = async (lesson) => {
        if (window.confirm(`Delete lesson , NAME: ${lesson.name} , DESCRIPTION: ${lesson.description}`)) {
            const alertDiv = document.getElementById(`user-alert-message-${lesson.id}`);
            alertDiv.classList.remove('d-none');
            setUserAlertMessage(`Deleting lesson , please wait.....`)

            const deleteButton = document.getElementById(`delete-lesson-button-${lesson.id}`)
            deleteButton.classList.add('d-none');
            try {
                const {status} = await axiosInstance.delete(`/topic-lessons/${lesson.id}/`)
                if(status==204){
                    setLessons(prev=>prev.filter(item=>item.id!==lesson.id))
                    setUserAlertMessage(`Lesson has been deleted successfully!`)
                }

            } catch (err) {
                console.log(err)
            } finally {
                setTimeout(()=>{
                    setIsFormSendingData(false)
                    setUserAlertMessage("")
                },3000)
            }
        }

    }

    const [isEdditingLesson, setIsEdittingLesson] = useState(false)
    const [lesson, setLesson] = useState({})

    const editTopicLesson = async (lessonParam) => {
        resetCRUDViewOperation()
        const alertDiv = document.getElementById(`user-alert-message-${lessonParam.id}`);
        alertDiv.classList.remove('d-none');
        setUserAlertMessage(`You are currently editting this lesson`)

        hideEditDeleteAddFileButtons(lessonParam)
        setLesson(lessonParam)
        setLessonFormData(lessonParam)
        setIsEdittingLesson(true)
    }

    const hideEditDeleteAddFileButtons = (lessonParam)=>{
        const deleteButton = document.getElementById(`delete-lesson-button-${lessonParam.id}`)
        deleteButton.classList.add('d-none');
        const editButton = document.getElementById(`edit-lesson-button-${lessonParam.id}`)
        editButton.classList.add('d-none');
        const addFileButton = document.getElementById(`add-file-button-${lessonParam.id}`)
        addFileButton.classList.add('d-none');
    }

    const showEditDeleteAddFileButtons = (lessonParam)=>{
        setIsAddingTopicLessonFile(false)
        const deleteButton = document.getElementById(`delete-lesson-button-${lessonParam.id}`)
        deleteButton.classList.remove('d-none');
        const editButton = document.getElementById(`edit-lesson-button-${lessonParam.id}`)
        editButton.classList.remove('d-none');
        const addFileButton = document.getElementById(`add-file-button-${lessonParam.id}`)
        addFileButton.classList.remove('d-none');

    }


    const updateLesson = async ()=>{
        setResponseMessage("Sending data, please wait....")
        setIsFormSendingData(true)
        const formData = { ...lessonFormData, "course_topic": topicId }
        try {
            const response = await axiosInstance.put(`/topic-lessons/${lesson.id}/`, formData)
            const responseData = response.data
            const status = response.status
            const { message, data } = responseData
            setResponseMessage(message)
            if(status==200){
                setLessons(prev=>{
                    return prev.map(item=>{
                        if(item.id == data.id) return data;
                        return item;
                    })
                })

                setTimeout(()=>cancelLessonEdit(), 1000)
            }
            console.log(status,message,data)
        } catch (err) {
            setResponseMessage(`Error ocurred , try again later! `)
            console.log(err)
        } finally {
            setTimeout(()=>{
                setIsFormSendingData(false)
            },1000)
        }
    }

    const cancelLessonEdit = () => {
        const alertDiv = document.getElementById(`user-alert-message-${lesson.id}`);
        alertDiv.classList.add('d-none');

        showEditDeleteAddFileButtons(lesson)
        setIsEdittingLesson(false)
        setUserAlertMessage("")
        setLesson({})

        setResponseMessage("")
        setLessonFormData({
            "description": "",
            "name": ""
        })

        setIsFormSendingData(false)
        setIsAddingTopicLessonFile(false)

    }


    const [isAddingTopicLessonFile, setIsAddingTopicLessonFile] = useState(false)

    const resetCRUDViewOperation = ()=> {
        if(lesson.id){
            setIsAddingTopicLessonFile(false)
            const alertDiv = document.getElementById(`user-alert-message-${lesson.id}`);
            alertDiv.classList.add('d-none');
            showEditDeleteAddFileButtons(lesson)
        }
    }

    const addTopicLessonFile = (lessonParam) =>{
        resetCRUDViewOperation()
        setLesson(()=>lessonParam)
        setIsAddingTopicLessonFile(true)

        hideEditDeleteAddFileButtons(lessonParam)

        const alertDiv = document.getElementById(`user-alert-message-${lessonParam.id}`);
        alertDiv.classList.remove('d-none');
        setUserAlertMessage(`You are currently adding a file to this lesson`)
    } 

    const CancelTopicLessonFileAddition = ()=>{
        if(!window.confirm("Cancel lesson file addition operation?")) return
        showEditDeleteAddFileButtons(lesson)
        setUserAlertMessage("")
        const alertDiv = document.getElementById(`user-alert-message-${lesson.id}`);
        alertDiv.classList.add('d-none');
        setLesson({})

    }
    return (
        <div className="container-fluid">
            <div className="row">

                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10">
                    <h4>Manage Topic Lessons</h4>

                    <Link to={`/admin/course/${courseId}`} style={{ position: "absolute", right: "40px", top: "63px" }}
                    className="btn btn-sm btn-outline-secondary ">Go back to Course</Link>

                    <div className="row mb-4">
                        <div className="col-md-3">
                            {course ? <CourseCard course={course} /> : <div className="alert alert-info mt-2">Course information not found</div>}
                        </div>
                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-body">
                                    <div className="alert alert-secondary">
                                        <small className="text-success">Topic description</small> <br />
                                        <small>
                                            {topic?.plan_value_proposition?.description}
                                        </small>
                                    </div>
                                    
                                    <LessonForm
                                        setIsAddingTopicLessonFile={setIsAddingTopicLessonFile}
                                        isAddingTopicLessonFile={isAddingTopicLessonFile}
                                        lesson={lesson}
                                        lessonFormData={lessonFormData}
                                        responseMessage={responseMessage}
                                        handleInputOnChange={handleInputOnChange}
                                        isFormSendingData={isFormSendingData}
                                        isEdditingLesson={isEdditingLesson}
                                        handleSubmit={handleSubmit}
                                        cancelLessonEdit={cancelLessonEdit}
                                    />

                                    <CreateTopicLessonFile
                                        isAddingTopicLessonFile={isAddingTopicLessonFile}
                                        lesson={lesson}
                                        setLessons={setLessons}
                                        CancelTopicLessonFileAddition={CancelTopicLessonFileAddition}
                                    />
                                </div>
                            </div>

                            <div className="card mt-3">

                                <div className="card-body">
                                    <div className="card-title">
                                        {
                                            lessons.length > 0 ?
                                                <>
                                                    <h5 className="mb-3 text-info">Existing lessons for this topic</h5>

                                                    <div className="list-group">
                                                        {
                                                            lessons.map(item =>
                                                                <Lesson key={item.id} lesson={item} 
                                                                    deleteTopicLesson={deleteTopicLesson}
                                                                    topicId={topicId}
                                                                    courseId={courseId}
                                                                    editTopicLesson={editTopicLesson}
                                                                    addTopicLessonFile={addTopicLessonFile}
                                                                    isAddingTopicLessonFile={isAddingTopicLessonFile}
                                                                    setIsAddingTopicLessonFile={setIsAddingTopicLessonFile}
                                                                    userAlertMessage={userAlertMessage} />
                                                            )
                                                        }
                                                    </div>

                                                </> :
                                                <div className="alert alert-info">This topic has no lessons available</div>
                                        }



                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default CreateTopicLesson;