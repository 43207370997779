
import React from 'react';

function PaginationControls({handlePageChange,totalPages,currentPage}) {
    return (
        <div style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center" }}>
            {Array.from({ length: totalPages }, (_, index) => (
                <button
                    type='button'
                    className='btn'
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    style={{
                        margin: "2px",
                        padding: "5px 10px",
                        backgroundColor: currentPage === index + 1 ? "#007BFF" : "#f0f0f0",
                        color: currentPage === index + 1 ? "#fff" : "#000",
                        border: "1px solid #ccc",
                        cursor: "pointer",
                    }}
                >
                    {index + 1}
                </button>
            ))}
        </div>
    )
}
export default PaginationControls;