import React, { useState } from "react"
import PaginationControls from "./PaginationControls";

function TopicForm({ handleTopicForm, topicFormData, handleTopicFormDataChange, planValuePropositions, topicResponseMessage,
    selectedPlans, isEditting, selectedServiceName = "", isServiceDefined = false,
    handlePlanCheckBox, alertType, services = [], isSendingTopicData }) {
    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 10;

    // Calculate the indices for the data to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = planValuePropositions.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(planValuePropositions.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return <>

        <div className={`mt-3 ${selectedPlans.length > 0 ? 'col-md-6' : "col-md-12"}`}>
            <div className={`card  ${isEditting ? "d-none" : ""}`}>
                <div className="card-body">


                    <form className='form row' onSubmit={handleTopicForm}>
                        <div className="row">
                            <div className="form-group">
                                <div className={`alert alert-${alertType} ${!isSendingTopicData ? "d-none" : ""}`}>{topicResponseMessage}</div>
                            </div>
                        </div>
                        {
                            !isServiceDefined && <>
                                <h5 className="card-title">Create new topic</h5>
                                <div className="row">

                                    <div className="form-group mt-2 col-md-11">
                                        <label htmlFor="company_service" className="label mb-2"><b>Service</b></label>
                                        <select value={topicFormData.serviceId} required onChange={handleTopicFormDataChange}
                                            name="company_service" id="company_service" className="form-control">
                                            <option key={0} value="">--select--</option>
                                            {services.map((item) => <option key={item.id} value={item.id}>{item.title}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="mt-3 row">
                            <h6 className="mt-2">Select topics below</h6>
                            <small className="text-info">You defined topics on the plan value proposition dashboard</small>
                            <div className="col-md-12">
                                <table className="table">
                                    <tbody>
                                        {
                                            currentItems.map(plan => {
                                                return <tr key={plan.id}>
                                                    <td style={{ width: "20px" }}>
                                                        {plan.id}
                                                    </td>
                                                    <td>
                                                        <div key={plan.id}  style={{ border: "solid #bcd3f7 1px", paddingLeft: "15px", borderRadius: "4px", backgroundColor: `${plan.isChecked ? "#198754" : "#fff"}`, color: `${plan.isChecked ? "#fff" : "#000"}` }}> <div className="form-check">
                                                            <input type="checkbox" onChange={(e) => handlePlanCheckBox(plan)} checked={plan.isChecked}
                                                                className="form-check-input" value={plan.isChecked} />
                                                            <label onClick={() => handlePlanCheckBox(plan)} className="form-check-label"><small>{plan.description}</small></label>
                                                        </div> </div>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                                <PaginationControls handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />
                            </div>
                        </div>

                        <div className="form-group mt-2">
                            <button type='submit' className={`btn mt-2 btn-outline-info ${isSendingTopicData ? "d-none" : ""}`}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className={` mt-3 ${selectedPlans.length > 0 ? "col-md-6" : "d-none"}`}>
            <div className='card'>
                <div className='card-body'>
                    {!isServiceDefined && <p><small><b>Selected service</b></small> {selectedServiceName}</p>}
                    <h6 className="mt-2 mb-2 text-success">Below are the topics you have selected</h6>
                    <ul className='list-group'>
                        {selectedPlans.map(item =>
                            <li key={item.id} className='list-group-item'>{item.id}.{item.description}
                                <button title={`click to remove ${item.description}`} onClick={() => handlePlanCheckBox(item)} className='btn btn-xs btn-outline-danger' type='button'>Remove</button>
                            </li>)}
                    </ul>
                </div>
            </div>
        </div>
    </>



}

export default TopicForm