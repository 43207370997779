import React, { useEffect, useState } from 'react';
import axiosInstance from '../config/axiosInstance';
import { useAuth } from './AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

function Login() {
    const location = useLocation();
    useEffect(()=>{
        if(location.pathname =="/login"){
            console.log(location.pathname)
            localStorage.clear("authToken")
        }
    },[location.pathname])
    const { login } = useAuth();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({"email":"", "password":""})
    const [alertType, setAlertType] = useState('info')
    const [showAlertMessage, setShowAlertMessage] = useState(false)
    const [alertMessage , setAlertMessage] = useState('')
    const [ isUserAttemptingLogin, setIsUserAttemptingLogin] = useState(false)
    const handleFormData = (event) => {
        const { name, value } = event.target
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (event)=>{
        event.preventDefault()
        setAlertType("info")
        setAlertMessage("Submitting form , please wait.....")
        setShowAlertMessage(true)
        setIsUserAttemptingLogin(true)
        try {
            const form_data = {...formData,"username":formData.email}
            const response = await axiosInstance.post('/token/',form_data)
            const {data,status} = response
            const {message,access} = data
            login()
            setAlertMessage(`${message}.You will now be redirected to home page.`)
            setAlertType("success")
            localStorage.setItem('authToken', access)
            setTimeout(()=>{
                navigate('/admin');
            }, 2000)
        } catch (error) {
            console.log(error)
            setAlertMessage("Error occured , try login again.")
            setAlertType("danger")
        } finally {
            setIsUserAttemptingLogin(false)
        }
    }


    return (
        <div className="container" style={{ padding: "20px", marginTop: "30px" }}>
            <div className='card col-md-6 mx-auto'>
                <div className='card-body'>
                    <h1 className='text-center'>Admin Login</h1>
                    <form className='form' onSubmit={handleSubmit}>
                        <div className={`alert alert-${alertType} ${!showAlertMessage?'d-none':''}`}>
                            {alertMessage}
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Email address</label>
                            <input type="email" required name='email' 
                            value={formData.email} onChange={handleFormData} 
                            className="form-control" id="exampleInputEmail1" 
                            aria-describedby="emailHelp" placeholder="Enter email" />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="exampleInputPassword1">Password</label>
                            <input type="password" required className="form-control"  name='password'
                             value={formData.password} onChange={handleFormData}
                             id="exampleInputPassword1" placeholder="Password" />
                        </div>
                        <button type="submit" className={`mt-3 btn btn-primary ${isUserAttemptingLogin ?'d-none':''}`}>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;