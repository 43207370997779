import React from 'react';
import { Link } from 'react-router-dom';
import FormatDate from './FormatDate';


export const server_base_url = process.env.REACT_APP_SERVER_BASE_URL

function ServiceRow({ service, editService }) {

    
    return (
        <>
            <tr>
                <td>{service.id}</td>
                <td className='text-left'>
                    { service.image && <><img src={server_base_url+service.image} width={80} className="rounded float-right" alt={service.title}/> <br/></>}
                
                    {service.title} 
                </td>
                <td width={500}>{service.description}</td>
                <td><FormatDate dateString={service.created_at} /></td>
                <td><FormatDate dateString={service.updated_at} /></td>
                <td width={200}>
                    <Link to={`/admin/course/${service.id}`} className='btn btn-sm btn-outline-info' 
                    title={service.title} >View</Link> &nbsp;
                    <button onClick={() => editService(service)} className='btn btn-sm btn-outline-success'>Edit</button>
                    
                </td>
            </tr>
            {service.topics.length > 0 && (
            <tr>
                <td colSpan={6}>
                <small><b>{service.title}</b> , has the following topics</small>
                    <ul className='list-group mt-2'>
                    {service.topics.map((item,index)=><li key={item.id} className='list-group-item small'>{index+=1}. {item.plan_value_proposition.description}</li>)}
                    </ul>
                    
                </td>
            </tr>
            )}

        </>
    );
}

export default ServiceRow;