import React, { useEffect, useState } from 'react'
import AdminSideBar from '../AdminSideBar'
import CustomAlertMessage from '../partials/CustomAlertMessage'
import axiosInstance from '../../config/axiosInstance'
import FormatDate from '../partials/FormatDate'
import PaginationControls from '../partials/PaginationControls'

function PlanValueProposition() {
    const [planValuePropositions, setPlanValuePropositions] = useState([])
    const [alertMessage, setAlertMessage] = useState()
    const [showAlertMessage, setShowAlertMessage] = useState(false)
    const [alertType, setAlertType] = useState('info')
    const [planId, setPlanId] = useState(null)
    const [formData, setFormData] = useState({
        "status": "DEACTIVATED",
        "description": ""
    })

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        displayAlertMessage("Fetching data, please wait", 'info')
        try {
            const response = await axiosInstance.get('/plan-value-proposition/')
            if (response.status == 200) {
                const responseData = response.data
                setPlanValuePropositions(responseData.data)
            }
        } catch (error) {
            displayAlertMessage("Error ocurred, contact admin", 'danger')
        } finally {
            setTimeout(() => hideAlertMessage(), 1000)
        }
    }

    const displayAlertMessage = (message, alert_type) => {
        setAlertMessage(message)
        setShowAlertMessage(true)
        setAlertType(alert_type)
    }

    const hideAlertMessage = () => {
        setAlertMessage("")
        setShowAlertMessage(false)
        setAlertType("info")
    }



    const handleSubmit = async (event) => {
        event.preventDefault()
        displayAlertMessage("Saving data, please wait", 'info')
        try {
            if(userIsEdittingData){
                const response = await axiosInstance.put(`/plan-value-proposition/${planId}/`, formData , {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                if(response.status == 200){
                    const { message ,data } = response.data
                    setPlanValuePropositions(prev=>prev.map(plan=>{
                        if(plan.id==data.id) return data
                        return plan
                    }))
                    displayAlertMessage(message, 'success')
                    setTimeout(()=>cancelEdit(), 1000)
                }
            }else{
                const response = await axiosInstance.post('/plan-value-proposition/', formData)
                if (response.status == 201) {
                    const { message } = response.data
                    displayAlertMessage(message, 'success')
                    setFormData({
                        "status": "DEACTIVATED",
                        "description": ""
                    })
                }
            }
        } catch (error) {
            const {message} = error
            displayAlertMessage(`${message}, contact admin`, 'danger')
        } finally {
            setTimeout(() => hideAlertMessage(), 1000)
        }
    }

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Calculate the indices for the data to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = planValuePropositions.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(planValuePropositions.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [formDisplay, setFormDisplay] = useState(false)
    const [userIsEdittingData, setUserIsEdditingData] = useState(false)

    const editPlan = (plan)=>{
        setPlanId(plan.id)
        setUserIsEdditingData(true)
        setFormDisplay(true)
        setFormData({
            "status":plan.status,
            "description":plan.description
        })
    }

    const cancelEdit = () =>{
        setPlanId(null)
        setUserIsEdditingData(false)
        setFormDisplay(false)
        setFormData({
            "status": "DEACTIVATED",
            "description": ""
        })
    }

    const deletePlan = async (plan)=>{
        if(window.confirm(`Delete: ${plan.description}`)){
            try {
                const response = await axiosInstance.delete(`/plan-value-proposition/${plan.id}/`)
                const {status} = response
                if(status == 204){
                    displayAlertMessage(`Plan "${plan.description}" has been deleted successfully!`, 'success')
                    setPlanValuePropositions(prev=>prev.filter(item=>plan.id!==item.id))
                }
            } catch (error) {
                const {message} = error
                displayAlertMessage(`${message}, contact admin`, 'danger')
            } finally {
                setTimeout(() => hideAlertMessage(), 1000)
            }
        }
    }

    return <div className="container-fluid">
        <div className="row">
            <div className="col-md-2">
                <AdminSideBar />
            </div>
            <div className="col-md-10">
                <h5 className='mt-2'>
                    Plan Value propositions
                </h5>
                <small>Plan value propositions are required when defining topics and also service plans</small>
                <div className='row'>

                    <div className='col-md-12 mt-2'>
                        <CustomAlertMessage alertType={alertType}
                            showAlertMessage={showAlertMessage}
                            alertMessage={alertMessage}
                        />
                        <button style={{ position: "absolute", right: "85px", top: "75px" }} onClick={() => setFormDisplay(prev => !prev)}
                            className={`btn btn-sm btn-outline-${formDisplay ? "secondary" : "info"}`} type='button'>
                            {formDisplay ? "View" : "Create"} Plan Value propositions
                        </button>
                    </div>

                    <div className={`mt-2 col-md-12 col-lg-12 col-sm-12 ${formDisplay ? "d-none" : ""}`}>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Created At</th>
                                    <th>Updated At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.length > 0 && currentItems.map(plan => <tr key={plan.id}>
                                    <td>{plan.id}</td>
                                    <td>{plan.description}</td>
                                    <td style={{ backgroundColor: `${plan.status == "DEACTIVATED" ? "red" : ""}`, color: `${plan.status == "DEACTIVATED" ? "white" : ""}` }}>{plan.status}</td>
                                    <td><small><FormatDate dateString={plan.created_at} /></small></td>
                                    <td><small><FormatDate dateString={plan.updated_at} /></small></td>
                                    <td className='d-flex'>
                                        <button type='button' className='btn btn-sm btn-outline-info' onClick={()=>editPlan(plan)}>Edit</button> &nbsp;
                                        <button type='button' className='btn btn-sm btn-outline-danger' onClick={()=>deletePlan(plan)}>Delete</button>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                        {/* Pagination Controls */}
                        <PaginationControls handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />
                    </div>

                    <form className={`form col-md-6 mt-2 ${!formDisplay ? "d-none" : ""}`} onSubmit={handleSubmit}>
                        <div className='form-group'>
                            <label htmlFor='status' className='label'>Status</label>
                            <select value={formData.status} onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' name='status' id='status' required>
                                <option value={"DEACTIVATED"}>DEACTIVATED</option>
                                <option value={"ACTIVATED"}>ACTIVATED</option>
                            </select>
                        </div>

                        <div className='form-group mt-2'>
                            <label htmlFor='description' className='label'>Description</label>
                            <textarea rows={10} value={formData.description} required
                            onChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))} 
                            className='form-control' name='description' id='description' placeholder='Enter description'></textarea>
                        </div>

                        <div className='form-group mt-2'>
                            <button className={`btn btn-outline-${userIsEdittingData ? "success" : "info"} btn-sm`} type='submit'>{userIsEdittingData ? "Update" : "Save"}</button>
                            &nbsp;&nbsp;<button className={`btn btn-sm btn-outline-secondary ${!userIsEdittingData ? "d-none" : ""}`} onClick={()=>cancelEdit()}>Cancel Edit</button>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    </div>
}

export default PlanValueProposition