import React from 'react';

import { server_base_url } from "../partials/ServiceRow";
import FormatDate from "../partials/FormatDate";

function CourseCard({course}) {
    return (
        <div className="card" >
            <img className="card-img-top mt-1" height={330}  src={server_base_url + course.image} alt={course.title} />
            <div className="card-body">
                <h5 className="card-title">{course.title}</h5>
                <p className="card-text">{course.description}</p>
                <small><b>Created On:</b> <FormatDate dateString={course.created_at} /></small> <br />
                <small><b>Last updated:</b> <FormatDate dateString={course.updated_at} /></small>

                <hr/>
                <p className='text-success'>
                    This course has <b>{course.topics.length}</b> topics.
                </p>
            </div>
        </div>

    );
}

export default CourseCard;