import React, { useEffect, useState } from 'react';
import AdminSideBar from '../AdminSideBar';
import axiosInstance from '../../config/axiosInstance';
import TopicInputFieldCheckBox from './TopicInputFieldCheckBox';
import CustomAlertMessage from '../partials/CustomAlertMessage';

function CreateServicePlan() {
    const [services, setServices] = useState([])
    const [alertMessage, setAlertMessage] = useState()
    const [showAlertMessage, setShowAlertMessage] = useState(false)
    const [alertType, setAlertType] = useState('info')
    const [displayServicePlanSelectionError, setShowServicePlanSelectionError] = useState(false)
    const [service, setService] = useState({
        "id": "",
        "description": "",
        "topics": []
    })

    const [servicePlanFormData, setServicePlanFormData] = useState({
        "name": "",
        "title": "",
        "description": "",
        "price": 0
    })

    const [servicePlans, setServicePlans] = useState([])
    const [showServicePlanCards, setShowServicePlanCards] = useState(false)
    useEffect(() => {
        fetchServices()
    }, [])


    const fetchServices = async () => {
        displayAlertMessage("Fetching services/courses from database. please wait...", 'info')
        try {
            const response = await axiosInstance.get('/services/')
            if (response.status == 200) {
                const responseData = response.data
                setServices(responseData.data)
            }
        } catch (error) {
            displayAlertMessage("Error ocurred, contact admin", 'danger')
        } finally {
            setTimeout(() => hideAlertMessage(), 1000)
        }
    }

    const displayAlertMessage = (message, alert_type) => {
        setAlertMessage(message)
        setShowAlertMessage(true)
        setAlertType(alert_type)
    }

    const hideAlertMessage = () => {
        setAlertMessage("")
        setShowAlertMessage(false)
        setAlertType("info")
    }

    const handleServiceInputChange = (e) => {
        setServicePlans([])
        hideAlertMessage()
        setShowServicePlanCards(false)
        const selectedService = services.find(item => item.id == e.target.value)
        setService(selectedService)
    }

    const handleServicPlanForm = async (e) => {
        e.preventDefault()
        if (service.topics.length == 0) {
            return displayAlertMessage("Selected service lacks topics , please update the service to includes topics , then define service plans", "danger")
        }
        hideAlertMessage()
        setShowServicePlanCards(true)
        const uiid = `id_${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;

        const currentServicePlan = {
            "name": servicePlanFormData.name,
            "title": servicePlanFormData.title,
            "description": servicePlanFormData.description,
            "price": servicePlanFormData.price,
            "company_service": service.id,
            "topics": service.topics.map(item => ({ ...item, isChecked: false })),
            "uiid": uiid,
            "card_bg": "bg-light",
            "selected_topics": []
        }
        setServicePlans(prev => ([...prev, currentServicePlan]))
        setServicePlanFormData({
            "name": "",
            "title": "",
            "description": "",
            "price": 0
        })

    }

    const handleServicePlanInputChange = (e) => {
        setServicePlanFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
    const removePlanFromUI = (uiid) => {
        const plan = servicePlans.find(plan => plan.uiid == uiid)
        if (plan) {
            const updatedPlans = servicePlans.filter((plan) => plan.uiid !== uiid);
            if (updatedPlans.length == 0) setShowServicePlanCards(false)
            setServicePlans(updatedPlans);
        } else {
        }


    }

    const handleTopicInputCheckBoxChange = (topic, plan, isChecked) => {
        if (displayServicePlanSelectionError) setShowServicePlanSelectionError(false)
        let selectedTopics = []
        if (isChecked) {
            selectedTopics = [...plan.selected_topics, topic.id]
        } else {
            selectedTopics = plan.selected_topics.filter(topicId => topicId !== topic.id)
        }

        const updatedTopics = plan.topics.map(topicItem => {
            if (topicItem.id == topic.id) {
                return { ...topicItem, isChecked }
            }
            return topicItem
        })

        const updatedPlan = { ...plan, "card_bg": "bg-light text-black", selected_topics: selectedTopics, topics: updatedTopics }

        setServicePlans(prev => prev.map(plan => {
            if (plan.uiid == updatedPlan.uiid) {
                return updatedPlan
            }
            return plan
        }))
    }

    const validateServicePlans = () => {
        setShowServicePlanSelectionError(false)
        let errorCount = 0
        const updatedPlans = servicePlans.map(plan => {
            if (plan.selected_topics.length == 0) {
                errorCount += 1
                return { ...plan, "card_bg": "bg-warning text-white" }
            }
            return plan
        })
        setServicePlans(updatedPlans)
        if (errorCount > 0) setShowServicePlanSelectionError(true)

        return errorCount
    }

    const saveServicePlans = async () => {
        const errorCount = validateServicePlans()
        if (errorCount == 0) {
            const service_plans = servicePlans.map((plan => {
                delete plan.topics
                delete plan.uiid
                return plan
            }))
            const plans = {
                "service_plans": service_plans
            }

            displayAlertMessage("Fetching services/courses from database. please wait...", 'info')
            try {
                const response = await axiosInstance.post('/service-plans/', plans)
                if (response.status == 200) {
                    const responseData = response.data
                    console.log(responseData)
                    resetForm()
                }
            } catch (error) {
                const { message } = error
                console.log(message)
                displayAlertMessage(`Error ocurred, contact admin : ${message}`, 'danger')
            } finally {
                setTimeout(() => hideAlertMessage(), 1000)
            }



        }

    }



    const resetForm = () => {
        setService({
            "id": "",
            "description": "",
            "topics": []
        })
        setServicePlans([])
        setServicePlanFormData({
            "name": "",
            "title": "",
            "description": "",
            "price": 0
        })
        setShowServicePlanCards(false)
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10">
                    <h3 className='mt-2'>
                        Create Service plans (Course Plans)
                    </h3>
                    <div className='row'>
                        <form className='form' id='service-display-form' onSubmit={handleServicPlanForm}>
                            <CustomAlertMessage alertType={alertType}
                                showAlertMessage={showAlertMessage}
                                alertMessage={alertMessage}
                            />
                            <div className='form-group'>
                                <label className='label mt-2' htmlFor='service'>Select Course/Service</label>
                                <select required name='service' value={service.id} onChange={(e) => handleServiceInputChange(e)} className='form-control mt-1'>
                                    <option value={''}>--select--</option>
                                    {services.map(item => <option value={item.id} key={item.id}>
                                        {item.title} || {item.description}
                                    </option>)}
                                </select>
                                <div className='mt-3 alert alert-secondary'>
                                    <p><b>Service Title </b> {service.title}</p>
                                    <p><b>Service Description </b>  {service.description}</p>
                                    <p><b>Topics Available</b> {service.topics.length}</p>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label className='label mt-1' htmlFor='name'>Service (course) plan name</label>
                                <input value={servicePlanFormData.name} onChange={(e) => handleServicePlanInputChange(e)} type='text'
                                    className='form-control' id='name' placeholder='Enter service(course) plan name' name='name' required />
                            </div>

                            <div className='form-group'>
                                <label className='label mt-1' htmlFor='title'>Title</label>
                                <input value={servicePlanFormData.title} onChange={(e) => handleServicePlanInputChange(e)} type='text'
                                    className='form-control' id='title' placeholder='Enter title' name='title' required />
                            </div>
                            <div className='form-group'>
                                <label className='label mt-1' htmlFor='price'>Price</label>
                                <input value={servicePlanFormData.price}
                                    onChange={(e) => handleServicePlanInputChange(e)} className='form-control'
                                    type='number' min={0} id='price' placeholder='Enter price' name='price' required />
                            </div>

                            <div className='form-group'>
                                <label className='label mt-1' htmlFor='description'>Description</label>
                                <textarea value={servicePlanFormData.description} onChange={(e) => handleServicePlanInputChange(e)} id='description' name='description' placeholder='Enter description' className='form-control'></textarea>
                            </div>
                            <div className='form-group'>
                                <button className='btn btn-sm btn-outline-info mt-2' type='submit'>Add service plan</button>
                            </div>

                        </form>

                        <div className={`row mt-3 ${!showServicePlanCards ? "d-none" : ""}`}>
                            <h3 className='mt-1 mb-3'>Define plans</h3>
                            {servicePlans.length > 0 && servicePlans.map((plan, planIndex) => {

                                return <div key={planIndex} className='col-md-4'>
                                    <div className={`card mb-3 ${plan.card_bg}`}>
                                        <div className='card-body'>
                                            <button type='button' id={plan.uiid} onClick={() => removePlanFromUI(plan.uiid)} style={{ position: "absolute", top: "10px", right: "10px" }}
                                                className='btn btn-sm btn-outline-danger' > Remove plan</button>
                                            <p className='mb-0'><b>Name </b> {plan.name} </p>
                                            <p className='mb-0'><b>Title </b> {plan.title} </p>
                                            <p className=''><b>Description </b> {plan.description} </p>
                                            <p className=''><b>Price </b> ${plan.price} </p>
                                            <p className='text-success fw-bold'>Select topics for the plan</p>
                                            <ul className='list-group'>
                                                {plan.topics && plan.topics.map((topic) => <TopicInputFieldCheckBox plan={plan}
                                                    handleTopicInputCheckBoxChange={handleTopicInputCheckBoxChange} key={topic.id} topic={topic} />)}
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            })}
                            <div className='mt-3'>
                                <div className={`alert alert-danger ${!displayServicePlanSelectionError ? "d-none" : ""}`}>
                                    You have some plans defined but you have not selected any topic, please select atleast one topic for a plan
                                </div>
                                <button type='button' className='btn btn-success' onClick={() => saveServicePlans()}>Save Service Plans</button>
                            </div>
                        </div>




                    </div>


                </div>
            </div>
        </div>

    );
}

export default CreateServicePlan;