import axios from 'axios'
const baseURL = process.env.REACT_APP_API_BASE_URL

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the token from localStorage
    const token = localStorage.getItem('authToken');

    // If the token exists, add it to the Authorization header
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor (optional, for handling errors globally)
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // You can handle specific error cases globally
    if (error.response && error.response.status === 401) {
      // For example, redirect to login if unauthorized
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
