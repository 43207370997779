import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';



function AdminSideBar() {
    const navbarList = [

        {
            name: "User Portal",
            url: "/"
        },
        {
            name: "Create Courses/Services",
            url: "/admin/course-create"
        },
        {
            name: "Courses/Services",
            url: "/admin/courses"
        },
        {
            name: "Create Service Plan (Course Plan)",
            url: "/admin/service-plan/create"
        },
        {
            name: "Service Plans",
            url: "/admin/service-plan"
        },
        {
            name: "Plan Value Proposition",
            url: "/admin/plan-value-proposition"
        },
        {
            name: "Content",
            url: "/admin/content"
        },
    ]
    
    const navigate = useNavigate();
    
    const logOut=()=>{
        localStorage.removeItem("authToken")
        navigate('/login')
    }

    const location = useLocation();
    return (
        <div className="container" style={{ padding: "20px" }}>
            <h6> Admin Portal </h6>
            <ul style={{ marginTop: "20px", marginBottom: "20px" }} className="list-group">
                {
                    navbarList.map((item, index) => (
                        <Link to={item.url} 
                            className={`list-group-item ${location.pathname === item.url ? "list-group-item-info" : ""}`}
                            key={index}>{item.name}</Link>
                    ))
                }
            </ul>
            <button className='btn btn-sm btn-outline-info' type='button' onClick={()=>logOut()}>Log out</button>
        </div>
    );
}
export default AdminSideBar;