import React, { Fragment, useEffect, useState } from 'react';
import AdminSideBar from '../AdminSideBar';
import axiosInstance from '../../config/axiosInstance';
import FormatDate from '../partials/FormatDate';
import PaginationControls from '../partials/PaginationControls';

function ServicePlan() {

    const [servicePlans, setServicePlans] = useState([])
    const [services, setServices] = useState([])
    const [alertMessage, setAlertMessage] = useState()
    const [alertType, setAlertType] = useState('info')
    const [showAlertMessage, setShowAlertMessage] = useState(false)

    useEffect(() => { fetchServicePlans() }, [])

    const fetchServicePlans = async () => {
        displayAlertMessage("Fetching services/courses from database. please wait...", 'info')
        try {
            const response = await axiosInstance.get('/service-plans/grouped-by-company/')
            if (response.status == 200) {
                const { data } = response.data
                console.log(data)
                setServices(data)
            }
        } catch (error) {
            displayAlertMessage("Error ocurred, contact admin", 'danger')
        } finally {
            setTimeout(() => hideAlertMessage(), 1000)
        }
    }

    const displayAlertMessage = (message, alert_type) => {
        setAlertMessage(message)
        setShowAlertMessage(true)
        setAlertType(alert_type)
    }

    const hideAlertMessage = () => {
        setAlertMessage("")
        setShowAlertMessage(false)
        setAlertType("info")
    }



    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    // Calculate the indices for the data to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = services.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(services.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };



    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <AdminSideBar />
                </div>
                <div className="col-md-10">
                    <h5 className='mt-1'>Service plan</h5>
                    <small className='text-success'>This page displays the services we are selling to customers</small>
                    <table className="table mt-2">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">Price</th>
                                <th scope="col">Created At</th>
                                <th scope="col">Updated At</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 && currentItems.map(service => {
                                return <Fragment key={service.service_id}>
                                    <tr>
                                        <th scope="row">{service.service_id}</th>
                                        <td colSpan={1}>{service.title}</td>
                                        <td colSpan={2}>{service.description}</td>
                                        <td><FormatDate dateString={service.created_at} /></td>
                                        <td><FormatDate dateString={service.updated_at} /></td>
                                        <td >
                                            <button className='btn btn-sm btn-outline-primary' type='button'>Edit</button>
                                        </td>
                                    </tr>
                                    <tr key={currentItems.length}>
                                        <td colSpan={7}>
                                            <div className='row'>

                                                {service.plans.length > 0 ?
                                                    <Fragment>
                                                        <h6 className='text-center'>{service.title} plans</h6>
                                                        {service.plans.map(plan =>
                                                            <div key={plan.id} className='card mx-auto col-md-3 m-2'>
                                                                <div className='card-body'>
                                                                    <span><small><b>Name</b>: {plan.name}</small> </span><br />
                                                                    <span><small><b>Description</b>: {plan.description}</small> </span><br />
                                                                    <span><small><b>Title</b>: ${plan.title}</small> </span><br />
                                                                    <span><small><b>Price</b>: {plan.price}</small> </span><br />

                                                                    {
                                                                        plan.plan_value_propositions.length > 0 ?
                                                                            <Fragment>
                                                                                <span><small><b>Offerings includes,</b></small> </span><br />

                                                                                <ul className='list-group mt-2'>
                                                                                    {plan.plan_value_propositions.length > 0 && plan.plan_value_propositions.map(item =>
                                                                                        <li key={item.id} className='list-group-item'><small>{item.value_proposition.description}</small></li>
                                                                                    )}
                                                                                </ul>
                                                                            </Fragment>
                                                                            :
                                                                            <div className='alert alert-info mt-2'>
                                                                                <small>
                                                                                    This plan lacks Offerings .Edit the service plan to include offerings for this specific plan.
                                                                                </small>
                                                                            </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                        )}
                                                    </Fragment>
                                                    :
                                                    <div className='alert alert-warning mt-2'>
                                                        <small>
                                                            This service <b>{service.title}</b> does not have plans defined!
                                                        </small>
                                                    </div>
                                                }
                                            </div>

                                        </td>
                                    </tr>
                                </Fragment>
                            })}
                        </tbody>
                    </table>

                    {/* Pagination Controls */}
                    <PaginationControls handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />
                </div>
            </div>
        </div>

    );
}

export default ServicePlan;