import React from 'react';
function Header({ activeTopicIndex,
    totalNumberOfSubTopics,
    activeLessonName,
    activeLessonDescription,
    courseName,
    activeSubTopic,
    activeTopicLessonIndex,
    handleActionLesson
}) {
    return (
        <div className="row">
            <div className="d-flex justify-content-center align-items-center"
                style={{ height: "100px", backgroundColor: "rgb(67, 82, 77)", color: "white" }}>
                <h4>{courseName} : {activeTopicIndex + 1} of {totalNumberOfSubTopics}- {activeSubTopic.name}</h4>
            </div>
            <div className="d-flex"
                style={{ paddingTop: "10px", height: "90px", backgroundColor: "rgb(72, 116, 101)", color: "white" }}>
                <div className="w-50">
                    <small>LESSON {activeTopicLessonIndex + 1} OF {activeSubTopic?.lessons?.length}</small> <br />
                    <span style={{ marginBottom: "0px !important", fontWeight: "bold", fontSize: "large" }}>{activeLessonName}</span> <br />
                    {activeLessonDescription !== "" ? <small>{activeLessonDescription}</small> : ""}

                </div>
                <div className="w-50 d-flex align-items-center">
                    <button onClick={() => handleActionLesson(activeTopicLessonIndex - 1)} className={`btn btn-outline-light btn-sm ${activeTopicLessonIndex === 0 ? "d-none" : ""}`}>Previous lesson</button>&nbsp;&nbsp;&nbsp;
                    <button onClick={() => handleActionLesson(activeTopicLessonIndex + 1)} className={`btn btn-outline-light btn-sm  ${activeTopicLessonIndex + 1 === activeSubTopic?.lessons?.length ? "d-none" : ""}`}>Next lesson</button>
                </div>
            </div>
        </div>
    )
}

export default Header;