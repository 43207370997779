import React from "react"

function TopicInputFieldCheckBox ({topic, plan, handleTopicInputCheckBoxChange}){
    const handleTopicCheckBox = ()=>{
        const isChecked = !topic.isChecked
        handleTopicInputCheckBoxChange(topic,plan,isChecked)
    }
    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked
        handleTopicInputCheckBoxChange(topic,plan, isChecked)
      };

    return <li onClick={() => handleTopicCheckBox()}  key={topic.id}
    className={`list-group-item cursor-pointer ${topic.isChecked ? "list-group-item-info" : ""}`}>
    <input type="checkbox"
          checked={topic.isChecked}
          onChange={(e)=>handleCheckboxChange(e)}/> &nbsp;
    {topic.plan_value_proposition.description}</li>
}

export default TopicInputFieldCheckBox