import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { server_base_url } from '../partials/ServiceRow';


function Topics({ topics, deleteTopicFromCourse }) {
    const { id } = useParams()

    return topics.map((item, topicIndex) =>
        <div key={item.id} className={`card ${topicIndex !== 0 ? "mt-2" : ""}`}>
            <div className="card-body">
                <span style={{ fontSize: "20px", fontWeight: "500", marginRight: "10px" }} className='text-info'>{item.id}.{item.plan_value_proposition.description}</span>
                <button title={item.description} type='button'
                    onClick={() => deleteTopicFromCourse(item)} className='btn btn-xs btn-outline-danger'>Delete topic</button>
                <hr />
                {item.lessons.length > 0 ? <small>This topic has the following lessons</small> : <small className='text-danger'>This topic has no lesson lessons</small>}
                <ul className="list-group list-group-flush">
                    {item.lessons.map((lesson, lessonIndex) => {
                        return <li className="list-group-item" key={lesson.index}>
                            <h6> <small className='text-success'>{item.id}:LESSON {lessonIndex + 1}.</small> {lesson.name}</h6>
                            <p>{lesson.description}</p>
                            {lesson.files && lesson.files.length > 0 ? lesson.files.map(item => {
                                const { file_type, file, id, url } = item
                                if (file_type === "IMAGE") {
                                    return <div id={`file-display-div-${id}`}
                                        className='text-center col-md-3 m-3'
                                        style={{ border: "1px solid #14121252", padding: "5px", borderRadius: "5px" }}>
                                        <img className="m-3" style={{ width: "200px" }} src={server_base_url + file} alt={lesson.description} />

                                    </div>
                                }
                                return ""

                            }) : ""}
                        </li>
                    })}
                </ul>
                {item.lessons.length == 0 && <hr />}
                <Link to={`/admin/course/${id}/create-topic-lesson/${item.id}`} title={item.description}
                    className='btn btn-sm btn-outline-success'>Manage lessons</Link>
            </div>
        </div>

    )
}

export default Topics;