import React from 'react';

function FormatDate({ dateString }) {
    const date = new Date(dateString);

    const formatter = new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium',
        timeStyle: 'short',
    });
    
    try {
        return <>{formatter.format(date)}</>;
    } catch (error) {
        
    }

    return "invalid date"
};


export default FormatDate;