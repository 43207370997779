import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./user/Home";
import CoursesIndex from "./admin/Courses/CoursesIndex";
import ViewCourse from "./admin/Courses/ViewCourse";
import AdminIndex from "./admin/AdminIndex"
import Login from "./auth/Login";
import CreateTopicLesson from "./admin/TopicLessons/CreateTopicLesson";
import ServicePlan from "./admin/ServicePlans/ServicePlan";
import Navbar from "./admin/partials/Navbar";
import CreateServicePlan from "./admin/ServicePlans/CreateServicePlan";
import CreateCourse from "./admin/Courses/CreateCourse";
import PlanValueProposition from "./admin/PlanValueProposition/PlanValueProposition";
import LessonContent from "./admin/TopicLessons/LessonContent";
import ContentIndex from "./admin/TopicLessons/ContentIndex";
function App() {
  return (
    <div className="container-fluid" style={{marginBottom:"100px"}}>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<AdminIndex />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin/courses" element={<CoursesIndex />} />
          <Route path="/admin/content" element={<ContentIndex />} />
          <Route path="/admin/course-create" element={<CreateCourse />} />
          <Route path="/admin/plan-value-proposition" element={<PlanValueProposition />} />
          <Route path="/admin/course/:id" element={<ViewCourse />} />
          <Route path="/admin/service-plan/create" element={<CreateServicePlan />} />
          <Route path="/admin/service-plan" element={<ServicePlan />} />
          <Route path="/admin/course/:courseId/create-topic-lesson/:topicId" element={<CreateTopicLesson />} />
          <Route path="/admin/course/:courseId/topic/:topicId/lesson/:lessonId/content" element={<LessonContent />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;