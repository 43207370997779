import React from 'react';
import { server_base_url } from '../partials/ServiceRow';
import axiosInstance from '../../config/axiosInstance';
import { Link } from 'react-router-dom';

function Lesson({ lesson, deleteTopicLesson, topicId, courseId, editTopicLesson, userAlertMessage, addTopicLessonFile }) {

    const removeFile = (fileId) => {
        if (!window.confirm(`Delete file ${fileId}`)) return
        const alertDiv = document.getElementById(`file-display-div-${fileId}`);
        alertDiv.classList.add('d-none');
        deleteFile(fileId)
    }

    const deleteFile = async (fileId) => {
        try {
            await axiosInstance.delete(`/topic-lesson-file/${fileId}/`)
            // console.log(response.data)

        } catch (error) {
            console.log(error)
        }

    }
    return <div key={lesson.id} className="list-group-item list-group-item-action flex-column align-items-start">
        <div className="d-flex w-100 justify-content-between">
            <h6 className="mb-1"><small>{lesson.id}:</small> {lesson.name}</h6>
        </div>
        <p key={lesson.id} className="mb-2">{lesson.description}</p>
        <div className='row p-1 mb-2'>
            {lesson.files && lesson.files.length > 0 ? lesson.files.map(item => {
                const { file_type, file, id, url } = item
                if (file_type === "IMAGE") {
                    return <div id={`file-display-div-${id}`}
                        className='text-center col-md-3 m-3'
                        style={{ border: "1px solid #14121252", padding: "5px", borderRadius: "5px" }}>
                        <img className="m-3" style={{ width: "200px" }} src={server_base_url + file} alt={lesson.description} />
                        <button onClick={() => removeFile(id)}
                            className='btn btn-sm btn-danger'
                            type='button'> <b>{id}</b> Delete Image</button>
                    </div>
                } else if (file_type === "DOCUMENT") {
                    return <div id={`file-display-div-${id}`}
                        className='text-center col-md-6 m-2'
                        style={{ border: "1px solid #14121252", padding: "5px", borderRadius: "5px" }}>
                        <embed
                            src={server_base_url + file}
                            type="application/pdf"
                            frameBorder="0"
                            scrolling="auto"
                            style={{ height: "350px", width: "100%" }}
                        />
                        <button className='btn btn-sm btn-danger'
                            onClick={() => removeFile(id)}
                            type='button'><b>{id}</b> Delete document</button>
                    </div>
                } else if (file_type === "URL") {
                    return <div id={`file-display-div-${id}`}
                        className='text-center col-md-6 m-3'
                        style={{ border: "1px solid #14121252", padding: "5px", borderRadius: "5px" }}>
                        <iframe style={{ height: "350px", width: "100%" }}
                            src={url}
                            title="Cassper Nyovest - Siyathandana ft. Abidoza, Boohle"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy={"strict-origin-when-cross-origin"}
                            allowFullScreen></iframe>
                        <button onClick={() => removeFile(id)}
                            className='btn btn-sm btn-danger'
                            type='button'><b>{id}</b> Delete Video</button>
                    </div>
                }
                return item.id
            })
                : <small>No files exist for this lesson</small>}
        </div>
        <div id={`user-alert-message-${lesson.id}`} className="alert alert-info d-none">
            {userAlertMessage}
        </div>
        <button id={`edit-lesson-button-${lesson.id}`} type="button" title={`Edit: ${lesson.name}`}
            onClick={() => editTopicLesson(lesson)} className="btn btn-sm btn-outline-secondary">Edit lesson</button> &nbsp;&nbsp;&nbsp;
        <button id={`delete-lesson-button-${lesson.id}`}
            title={`Delete: ${lesson.name}`} type="button" onClick={() => deleteTopicLesson(lesson)}
            className="btn btn-sm btn-outline-danger">Delete lesson</button>
        &nbsp;&nbsp;&nbsp;
        <button id={`add-file-button-${lesson.id}`}
            title={`Add file: ${lesson.name}`} type="button" onClick={() => addTopicLessonFile(lesson)}
            className="btn btn-sm btn-outline-info">Add new file</button>

        &nbsp;&nbsp;&nbsp; 
        <Link to={`/admin/course/${courseId}/topic/${topicId}/lesson/${lesson.id}/content`}
            title={`Add lesson content: ${lesson.name}`} 
            className="btn btn-sm btn-outline-success">Add content</Link>

    </div>
}

export default Lesson;