import React from 'react';

function LessonForm({ lessonFormData, responseMessage, handleInputOnChange, isFormSendingData,isEdditingLesson, lesson,
    isAddingTopicLessonFile,
    handleSubmit, cancelLessonEdit }) {
    return <form className={`form ${isAddingTopicLessonFile ? "d-none" : ""}`} onSubmit={handleSubmit}>
        <h5 className="mb-2">{isEdditingLesson ? `Editting Lesson ${lesson.id}: ${lesson.name}` : 'Create new lesson for this topic'}</h5>
        <div className="form-group mt-2">
            <label htmlFor="name" className="label">Name</label>
            <textarea type="text" required name='name' className="form-control mt-1" id="name"
                value={lessonFormData.name} onChange={handleInputOnChange} placeholder="Enter name" />
        </div>

        <div className="form-group mt-2">
            <label htmlFor="description" className="label">Description</label>
            <textarea type="text" required name='description' className="form-control mt-1" id="description"
                value={lessonFormData.description} onChange={handleInputOnChange} placeholder="Enter description" />
        </div>
        <div className={`form-group mt-3 alert alert-info ${!isFormSendingData ? "d-none" : ""}`}>
            {responseMessage}
        </div>
        <div className={`form-group mt-3 ${isFormSendingData ? "d-none" : ""}`}>
            <button className={`btn btn-sm btn-${isEdditingLesson ? "primary" : "success"}`} type="submit">
                {isEdditingLesson ? "Update lesson" : "Create new lesson"}
            </button> &nbsp;
            <button onClick={() => cancelLessonEdit()}
                className={`btn btn-sm btn-secondary ${!isEdditingLesson ? "d-none" : ""}`}
                type="button">
                Cancel edit
            </button>
        </div>
    </form>
}

export default LessonForm;

