import React, { useState } from "react";
import FormatDate from "../partials/FormatDate";
import PaginationControls from "../partials/PaginationControls";

function ContentTable({ content, editContent = () => { }, deleteContent = () => { }, viewContent = () => { }, hideActionButtons = false }) {
    // Pagination state

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Calculate the indices for the data to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = content.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(content.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (
        <>
            <small> <b>{content.length}</b> content items created.  </small>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Created On</th>
                        <th>Updated On</th>
                        {!hideActionButtons && <th>Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {currentItems.length > 0 ? currentItems.map(item => <tr key={item.id}>
                        <th>{item.id}</th>
                        <td>
                            {item.title}
                        </td>
                        <td>
                        {item.name}
                        </td>
                        <td>
                            {item.description}
                        </td>
                        <td><FormatDate dateString={item.updated_at} /></td>
                        <td><FormatDate dateString={item.created_at} /></td>

                        {
                            !hideActionButtons && <td className="d-flex">
                                <button title={`View : ${item.description}`} className="btn btn-sm btn-outline-success" type="button" onClick={() => viewContent(item.id)}>View</button> &nbsp;&nbsp;
                                <button title={`Edit : ${item.description}`} className="btn btn-sm btn-outline-info" type="button" onClick={() => editContent(item.id)}>Edit</button> &nbsp;&nbsp;
                                <button title={`Delete : ${item.description} !`} className="btn btn-sm btn-outline-danger" type="button" onClick={() => deleteContent(item)}>Delete</button>
                            </td>
                        }


                    </tr>) : <tr><td colSpan={7} className="text-center">No data found!</td></tr>}
                </tbody>
            </table>
            {/* Pagination Controls */}
            <PaginationControls handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />
        </>
    );
}

export default ContentTable;