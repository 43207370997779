const course = "Resume Writing"
const topics = [
    {
        "name": "Understanding Resume Basics",
        "lessons": [
            {
                "name": "What is a Resume?",
                "description": "",
                "content": [
                    {
                        "title": "Introduction",
                        "description": "A resume is a formal document that provides a concise summary of an individual's professional background, skills, accomplishments, and education.It is typically used as part of the job application process to showcase qualifications and suitability for a specific role."
                    },
                    {
                        "title": "Purpose and Importance of a Resume",
                        "description": "<ol><li><b>First Impression:</b> A resume serves as the first point of contact between a candidate and a potential employer, making it a critical tool for creating a positive initial impression.</li><li><b>Showcasing Skills and Achievements:</b>It highlights your professional skills, experiences, and accomplishments relevant to the job.</li><li><b>Demonstrating Fit: </b>Employers use resumes to quickly assess if a candidate meets the basic requirements for the role.</li><li><b>Guiding the Interview:</b>A well-written resume often serves as a guide for interviewers, helping them formulate questions and evaluate the candidate.</li><li><b>Marketing Tool: </b>It is a personal branding document that markets your unique qualifications and strengths.</li></ol>"
                    },
                    {
                        "title": "Watch Video",
                        "description": 'Creating an incredible resume involves making a strong first impression and showcasing your skills and experiences effectively. Here are 5 Golden Rules to follow when crafting a standout resume:<br><iframe width="840" height="472" src="https://www.youtube.com/embed/Tt08KmFfIYQ?si=k-jiEfkH9IjlL393" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>'
                    },
                    {
                        "title": "Difference Between a Resume, CV, and Cover Letter",
                        "description": "<table class='table'><thead><tr><th><strong>Aspect</strong></th><th><strong>Resume</strong></th><th><strong>CV (Curriculum Vitae)</strong></th><th><strong>Cover Letter</strong></th></tr></thead><tbody><tr><td><strong>Definition</strong></td><td>A concise summary of work experience, skills, and education.</td><td>A detailed and comprehensive overview of academic and professional history.</td><td>A personalized letter explaining why you’re the best fit for the job.</td></tr><tr><td><strong>Length</strong></td><td>Typically 1-2 pages.</td><td>Can be multiple pages, depending on experience (often 2+).</td><td>1 page.</td></tr><tr><td><strong>Focus</strong></td><td>Tailored to a specific job or role.</td><td>Broad and detailed, emphasizing academic and research achievements.</td><td>Focused on connecting skills and experience to the job requirements.</td></tr><tr><td><strong>Use</strong></td><td>Common in the private sector for job applications.</td><td>Common in academia, research, and international roles.</td><td>Always accompanies a resume or CV in a job application.</td></tr><tr><td><strong>Customization</strong></td><td>Highly tailored to the job description.</td><td>Less tailored, usually a comprehensive record.</td><td>Highly customized to the company and role.</td></tr></tbody></table>"
                    },
                    {
                        "title": "Summary",
                        "description": "<ul><li>A <strong>resume</strong> is a concise, tailored document meant for quick review by employers.</li><li>A <strong>CV</strong> is a detailed academic and professional history, often used in academic or research contexts.</li><li>A <strong>cover letter</strong> complements both by explaining your motivations and how your qualifications align with the role.</li></ul>"
                    }
                ]
            },
            {
                "name": "Purpose and Importance of a Resume",
                "description": "",
                "content": []
            },
            {
                "name": "Difference Between a Resume, CV, and Cover Letter",
                "description": "",
                "content": []
            }
        ]
    },
    {
        "name": "Types of Resumes",
        "lessons": [
            {
                "name": "Chronological Resume",
                "description": "Highlighting work history.",
                "content": []
            },
            {
                "name": "Functional Resume",
                "description": "Focusing on skills and achievements.",
                "content": []
            },
            {
                "name": "Combination Resume",
                "description": "A mix of work history and skills.",
                "content": []
            },
            {
                "name": "Targeted Resume",
                "description": "Tailored for a specific job.",
                "content": []
            }
        ]
    },
    {
        "name": "Resume Structure and Sections",
        "lessons": [
            {
                "name": "Header",
                "description": "Contact information.",
                "content": []
            },
            {
                "name": "Professional Summary or Objective",
                "description": "A brief snapshot of your profile.",
                "content": []
            },
            {
                "name": "Work Experience",
                "description": "Job titles, companies, accomplishments.",
                "content": []
            },
            {
                "name": "Education",
                "description": "Degrees, certifications, institutions, dates.",
                "content": []
            },
            
            {
                "name": "Skills",
                "description": "Relevant technical and soft skills.",
                "content": []
            },
            {
                "name": "Certifications and Licenses",
                "description": "Additional qualifications.",
                "content": []
            },            
            {
                "name": "Projects",
                "description": "Specific accomplishments or portfolios.",
                "content": []
            },
            {
                "name": "Volunteer Work",
                "description": "Community or nonprofit involvement.",
                "content": []
            },
            {
                "name": "Hobbies and Interests",
                "description": " If relevant to the job.",
                "content": []
            }
        ]
    },
    {
        "name":"Writing Tips for Each Section",
        "lessons":[
            {
                "name": "Action-Oriented Language",
                "description": 'Using action verbs like "managed," "designed," "optimized."',
                "content": []
            },
            {
                "name": "Quantifying Achievements",
                "description": ', “Increased sales by 20%.”',
                "content": []
            },
            {
                "name": "Tailoring for ATS",
                "description": "Using keywords from job descriptions.",
                "content": []
            },
            {
                "name": "Conciseness",
                "description": "Keeping content brief and relevant.",
                "content": []
            }
        ]
    },
    {
        "name": "Customizing Your Resume",

        "lessons": [
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            }

        ]

    },
    {
        "name": "Designing and Formatting a Resume",

        "lessons": [
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            }

        ]

    },
    {
        "name": "Common Mistakes to Avoid",

        "lessons": [
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            }

        ]

    },
    {
        "name": "Industry-Specific Considerations",
        "lessons": [
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            }

        ]

    },
    {
        "name": "Modern Trends in Resume Writing",
        "lessons": [
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            }

        ]

    },
    {
        "name": "Tools for Resume Writing",
        "lessons": [
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            }

        ]

    },
    {
        "name": "Evaluating and Updating Your Resume",
        "lessons": [
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            }

        ]

    },
    {
        "name": "Advanced Resume Techniques",
        "lessons": [
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            }

        ]

    },
    {
        "name": "Using AI and Technology",
        "lessons": [
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            }

        ]

    },
    {
        "name": " Cultural and Regional Adaptations",
        "lessons": [
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },
            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            },            
            {
                "name": "",
                "description": "",
                "content": []
            },
            {
                "name": "",
                "description": "",
                "content": []
            }

        ]

    },
]

const pageData = {
    "course": course,
    "topics": topics
}



export {
    pageData
}